<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <canvas ref="canvas" class="min-h-full w-full"></canvas>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useBreakpoint } from '@ui'

const breakpoint = useBreakpoint()
const canvas = ref(null)
const running = ref(true)
const fontSize = (val) => {
  if (breakpoint.xs) return 0.55 * val
  if (breakpoint.sm) return 0.8 * val
  return val
}

onBeforeUnmount(async () => {
  running.value = false
})

onMounted(() => {
  const ctx = canvas.value.getContext("2d");
  const colors = [
    "#DC2626",
    "#FBBF24",
    "#3B82F6",
    "#10B981",
    "#EC4899",
    "#8B5CF6",
    "#F472B6"
  ];
  let linePos = 0,
    rAF;

  canvas.value.width = canvas.value.offsetWidth;
  canvas.value.height = canvas.value.offsetHeight;

  function texts(color) {
    ctx.font = `${fontSize(7)}em Bungee Outline`;
    ctx.shadowBlur = 30;
    ctx.shadowColor = color;
    ctx.fillStyle = color;
    ctx.setTransform(1, -0.15, 0, 1, 0, -120);
    ctx.fillText("Stealth", canvas.value.offsetWidth / 2, canvas.value.offsetHeight / 2 - 5);

    ctx.fillStyle = "white";
    ctx.shadowBlur = 30;
    ctx.shadowColor = color;
    ctx.fillText("Stealth", canvas.value.offsetWidth / 2, canvas.value.offsetHeight / 2);

    ctx.font = `${fontSize(6)}em Bungee Inline`;
    ctx.shadowBlur = 30;
    ctx.shadowColor = color;
    ctx.fillStyle = "#fff";
    ctx.setTransform(1, -0.15, 0, 1, 0, -160);
    ctx.fillText(
      "Drop #1",
      canvas.value.offsetWidth / 2,
      canvas.value.offsetHeight / 2 + canvas.value.offsetHeight / 10
    );
    
    ctx.font = `${fontSize(4)}em Bungee Inline`;
    ctx.setTransform(1, 0, 0, 1, 0, -100);
    ctx.fillText(
      "Coming soon.",
      canvas.value.offsetWidth / 2,
      canvas.value.offsetHeight / 2 + canvas.value.offsetHeight / 10
    );
    
    ctx.font = `${fontSize(3)}em Bungee Inline`;
    ctx.setTransform(1, 0, 0, 1, 0, -40);
    ctx.fillText(
      "Snek holders only.",
      canvas.value.offsetWidth / 2,
      canvas.value.offsetHeight / 2 + canvas.value.offsetHeight / 10
    );

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
  }

  function glitch() {
    if (running.value) rAF = window.requestAnimationFrame(glitch);

    ctx.fillStyle = "#1F2937";
    ctx.fillRect(0, 0, canvas.value.offsetWidth, canvas.value.offsetHeight);

    texts(colors[Math.floor(Math.random() * 7)]);
    ctx.shadowBlur = 0;
    ctx.shadowColor = "none";
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    for (let i = 0; i < 1000; i++) {
      ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.01})`;
      ctx.fillRect(
        Math.floor(Math.random() * canvas.value.offsetWidth),
        Math.floor(Math.random() * canvas.value.offsetHeight),
        Math.floor(Math.random() * 30) + 1,
        Math.floor(Math.random() * 30) + 1
      );

      ctx.fillStyle = `rgba(0,0,0,${Math.random() * 0.1})`;
      ctx.fillRect(
        Math.floor(Math.random() * canvas.value.offsetWidth),
        Math.floor(Math.random() * canvas.value.offsetHeight),
        Math.floor(Math.random() * 25) + 1,
        Math.floor(Math.random() * 25) + 1
      );
    }
    
    ctx.fillStyle = colors[Math.floor(Math.random() * 40)];
    ctx.fillRect(
      Math.random() * canvas.value.offsetWidth,
      Math.random() * canvas.value.offsetHeight,
      Math.random() * canvas.value.offsetWidth,
      Math.random() * canvas.value.offsetHeight
    );
    ctx.setTransform(1, 0, 0, .8, .2, 0);    
  }

  window.addEventListener('resize', () => {
    canvas.value.width = canvas.value.offsetWidth;
    canvas.value.height = canvas.value.offsetHeight;
  });

  glitch();
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Outline&display=swap');
</style>